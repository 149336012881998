export enum TARGET {
    PRODUCTION = "production",
    DEVELOPMENT = "development",
    STAGING = "staging",
    E2E = "e2e",
}

export const BUILD_TARGET = (process.env.NEXT_PUBLIC_TARGET as TARGET) ?? TARGET.DEVELOPMENT

export const DOMAIN_PREFIX = process.env.DEV_SERVER ? "" : process.env.NEXT_PUBLIC_REST_API || ""
export const REST_API_URL = DOMAIN_PREFIX + "/api"

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_TARGET === "production"
export const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_TARGET === "development"
export const IS_STAGING = {
    [TARGET.E2E]: true,
    [TARGET.STAGING]: true,
    [TARGET.DEVELOPMENT]: false,
    [TARGET.PRODUCTION]: false,
}[BUILD_TARGET]
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || ""
export const REACT_QUERY_DEV_TOOLS_ENABLED =
    process.env.NEXT_PUBLIC_REACT_QUERY_DEV_TOOLS_ENABLED === "true"

export const APP_PAGE_URL = process.env.NEXT_PUBLIC_APP_PAGE_URL || "https://alter.ru"
export const SURVEY_URL = `${APP_PAGE_URL}/survey/psy`
export const BLOG_URL = `https://blog.psyalter.ru`

export const CLOUDPAYMENTS_PUBLIC_ID = process.env.NEXT_PUBLIC_CLOUDPAYMENTS_PUBLIC_ID
export const GOOGLE_OAUTH2_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OAUTH2_CLIENT_ID || ""
export const GOOGLE_GTAG_ID = process.env.NEXT_PUBLIC_GOOGLE_GTAG_ID || ""
export const GOOGLE_OAUTH2_SCOPE =
    "https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"

export const PSYALTER_SUPPORT_EMAIL = "support@psyalter.com"

export const YANDEX_METRIKA_COUNTER_ID = process.env.NEXT_PUBLIC_YANDEX_METRIKA_COUNTER_ID || ""
export const VK_PIXEL_COUNTER_ID = process.env.NEXT_PUBLIC_VK_PIXEL_COUNTER_ID as number | undefined

export const PERCENT_OF_SELECTED_PSYCHOLOGISTS = "13%"

export const PSYALTER_SUPPORT_TELEGRAM = "https://t.me/psyalter_bot"

export const PSYALTER_SUPPORT_TELEGRAM_NAME = "@psyalter_bot"

export const PERSONAL_INFO = `${APP_PAGE_URL}/personalinfo`

export const TERMS_OF_USE = `${APP_PAGE_URL}/eula`

export const INDIVIDUAL_OFFER = `${APP_PAGE_URL}/individual_offer`

export const INFORMED_AGREEMENT = `${APP_PAGE_URL}/informed_agreement`

export const STRIPE_PAGE_URL = process.env.NEXT_PUBLIC_STRIPE_PAGE_URL as string
export const STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
export const GAZPROMPAY_PAGE_URL = process.env.NEXT_PUBLIC_GAZPROMPAY_PAGE_URL as string
export const GAZPROMPAY_MERCH_ID = process.env.NEXT_PUBLIC_GAZPROMPAY_MERCH_ID

const voximplantDomains: Record<TARGET, string> = {
    [TARGET.PRODUCTION]: "alter-production.a-aniskin.n4.voximplant.com",
    [TARGET.DEVELOPMENT]: "dev.a-aniskin.n4.voximplant.com",
    [TARGET.STAGING]: `alter-staging${APP_ENV}.a-aniskin.n4.voximplant.com`,
    [TARGET.E2E]: `alter-staging${APP_ENV}.a-aniskin.n4.voximplant.com`,
}
export const VOXIMPLANT_DOMAIN = voximplantDomains[BUILD_TARGET] ?? voximplantDomains["development"]
export const SESSION_LENGTH = 50 // минуты

export const PSYCHOLOGIST_COUNT = 900

export const PROMO_CODE_BANNER_SCRIPT_URL = `${DOMAIN_PREFIX}/js/promo-code-banner/index.js?v=1`

export const INCLUDE_PROMO_CODE_BANNER_JS =
    process.env.NEXT_PUBLIC_INCLUDE_PROMO_CODE_BANNER_JS === "true" || IS_PRODUCTION || IS_STAGING

export const GROWTH_BOOK_URL = process.env.NEXT_PUBLIC_GROWTH_BOOK_URL
export const GROWTH_BOOK_KEY = IS_STAGING
    ? process.env.NEXT_PUBLIC_GROWTH_BOOK_STAGE_KEY
    : process.env.NEXT_PUBLIC_GROWTH_BOOK_PRODUCTION_KEY
export const ANALYTIC_DEBUG_SHOW_LOG =
    process.env.NEXT_PUBLIC_ANALYTICS_DEBUG_LOG === "true" || IS_STAGING
